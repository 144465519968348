import React, {ChangeEvent, Suspense} from 'react';
import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter,
    Link,
    NavLink
} from "react-router-dom";
import Contact from "./routes/contact";
import Imprint from "./routes/imprint";
import Projects from "./routes/projects";
import Customers from "./routes/customers";
import Intro from "./routes/intro";
import Markdown_renderer from "./routes/project/markdown_renderer";

import {Trans, useTranslation} from 'react-i18next';

import './i18n'

function App() {
    function github_project_data(project_name: string, project_id: string, branch: string) {
        return {
            project_name: project_name,
            project_url: "https://github.com/schuellerf/" + project_id,
            base_URL: "https://raw.githubusercontent.com/schuellerf/" + project_id + "/" + branch + "/",
            markdown: "README.md"
        }
    }

    const emoji_expert_data = github_project_data("Emoji Expert", "telegram-emojiexpert", "master")
    const xfce_test_data = github_project_data("XFCE Test", "xfce-test", "master")
    const setgpspos_data = github_project_data("OsmAnd A/V Notes to JOSM converter", "setGPSPos", "main")
    const sundial_data = github_project_data("Sundial", "sundial", "main")
    const xfconf_to_xrandr_data = github_project_data("XFCE Display Profiles for LightDM", "xfconf_to_xrandr", "master")
    const check_my_net_data = github_project_data("Check My Net", "check_my_net", "master")

    function changeLanguage(option: ChangeEvent<HTMLSelectElement>) {
        let new_lang = option.target.value ?? "de";
        let x = i18n.changeLanguage(new_lang);

    }

    let langs = [
        {value: 'de', label: 'Deutsch'},
        {value: 'en', label: 'English'}
    ];

    const {t, i18n} = useTranslation('',{ keyPrefix: 'app' });

    return (
        <Suspense fallback="loading">
        <div className="App">
            <div className="App-header">
                <HashRouter>
                    <ul className="header">
                        <li><NavLink end to="/">🏠</NavLink></li>
                        <li><NavLink to="/project">{t('projects')}</NavLink></li>
                        <li><NavLink to="/customers">{t('customers')}</NavLink></li>
                        <li><NavLink to="/contact">{t('contact')}</NavLink></li>
                        <li><NavLink to="/imprint">{t('imprint')}</NavLink></li>
                        <li><select className={"language_selector"} name={"language"} defaultValue={i18n.language} onChange={changeLanguage}>
                            {langs.map(({value, label}) => <option className={"language_selector_option"} key={value} value={value}>{label}</option>)}
                        </select></li>
                    </ul>
                    <div className={"content_container"}>
                        <Routes>
                            <Route path="/" element={<Intro />}/>
                            <Route path="/project" element={<Projects/>}/>

                            <Route path="/project/emojiexpert" element={<Markdown_renderer {...emoji_expert_data} />}/>
                            <Route path="/project/xfce-test" element={<Markdown_renderer {...xfce_test_data} />}/>
                            <Route path="/project/setgpspos" element={<Markdown_renderer {...setgpspos_data} />}/>
                            <Route path="/project/sundial" element={<Markdown_renderer {...sundial_data} />}/>
                            <Route path="/project/xfconf_to_xrandr"
                                   element={<Markdown_renderer {...xfconf_to_xrandr_data} />}/>
                            <Route path="/project/check_my_net" element={<Markdown_renderer {...check_my_net_data} />}/>


                            <Route path="/customers" element={<Customers/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/imprint" element={<Imprint/>}/>
                        </Routes>
                    </div>
                </HashRouter>

            </div>
        </div>
        </Suspense>
    );
}

export default App;
