import '../App.css';
import {useTranslation} from "react-i18next";
import React from "react";

export default function Customers() {
    const {t, i18n} = useTranslation('', {keyPrefix: 'customers'});
    return (
        <div className="content">
            <h2><a href='https://capacity.at/' target="_blank">Capacity Blockchain Solutions</a></h2>
            <p className={"description"}>{t('capacity_description')}</p>
            <ul className="subheader">
                {(t('capacity_list', {returnObjects: true}) as []).map((item: string) => <li key={item}>{item}</li>)}
            </ul>
        </div>
    );
}
