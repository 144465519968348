import '../App.css';
import {HashRouter, Link, NavLink, Route, Routes} from "react-router-dom";
import React from "react";
import {useTranslation, Trans} from "react-i18next";

function paypal_button(button_id: string) {
    return `<form action="https://www.paypal.com/donate" method="post" target="_top" class=${"donate"}>
<input type="hidden" name="hosted_button_id" value="${button_id}" />
<input type="image" src="https://www.paypalobjects.com/en_US/AT/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_AT/i/scr/pixel.gif" width="1" height="1" />
</form>
`
}

export default function Projects() {
    const {t, i18n} = useTranslation('', {keyPrefix: 'projects'});

    const paypal_xfcetest = paypal_button("ZWBG7DE2TVCAA");
    const paypal_emojiexpert = paypal_button("HRUEUXWX52QNJ");
    const paypal_sundial = paypal_button("AJS9JLRM3LZW6");
    return (
        <div className="content">
            <h2>{t('own_projects')}</h2>
            <p><Trans i18nKey={"own_projects_link"} t={t}>Projekte auf github sind hier zu finden: <a
                href='https://github.com/schuellerf?tab=repositories&q=&type=source'>github/schuellerf</a></Trans></p>
            <p>{t('own_project_description')}</p>
            <p>{t('own_project_mobile')}</p>
            {/* https://www.paypal.com/donate/buttons/manage
             xfce-test
             https://www.paypal.com/donate/?hosted_button_id=ZWBG7DE2TVCAA

             emojiexpert
             https://www.paypal.com/donate/?hosted_button_id=HRUEUXWX52QNJ

             sundial
             https://www.paypal.com/donate/?hosted_button_id=AJS9JLRM3LZW6
             */}
            <ul className="subheader">
                <li><Link to="emojiexpert">Telegram Emoji Expert Bot</Link><br/>
                    <table className={"donate"}>
                        <tr className={"donate"}>
                            <td className={"donate"}>{t('donate')}</td>
                            <td className={"donate"}>
                                <div dangerouslySetInnerHTML={{__html: paypal_emojiexpert}}/>
                            </td>
                        </tr>
                    </table>
                </li>
                <li><Link to="setgpspos">OsmAnd A/V Notes to JOSM converter</Link></li>
            </ul>
            <p>{t('own_project_linux')}</p>
            <ul className="subheader">
                <li><Link to="xfce-test">XFCE Test</Link><br/>
                    <table className={"donate"}>
                        <tr className={"donate"}>
                            <td className={"donate"}>{t('donate')}</td>
                            <td className={"donate"}>
                                <div dangerouslySetInnerHTML={{__html: paypal_xfcetest}}/>
                            </td>
                        </tr>
                    </table>
                </li>
                <li><Link to="xfconf_to_xrandr">XFCE Display Profile für LightDM</Link></li>
                <li><Link to="check_my_net">Check My Net</Link></li>
            </ul>
            <p>{t('own_project_create')}</p>
            <ul className="subheader">
                <li><Link to="sundial">{t('sundial')}</Link><br/>
                    <table className={"donate"}>
                        <tr className={"donate"}>
                            <td className={"donate"}>{t('donate')}</td>
                            <td className={"donate"}>
                                <div dangerouslySetInnerHTML={{__html: paypal_sundial}}/>
                            </td>
                        </tr>
                    </table>
                </li>
            </ul>
            <h2>{t('contributed_projects')}</h2>
            <p><Trans i18nKey={"contributed_projects_link"} t={t}>Projekte auf github sind hier zu finden: <a
                href='https://github.com/schuellerf?tab=repositories&type=fork'>github/schuellerf</a></Trans></p>

            <h2>{t('own_project_3d')}</h2>
            <ul className="subheader">
                <li><a href="https://www.thingiverse.com/schuellerf/designs">{t('thingiverse')}</a><br/>
                    {/*<table className={"donate"}>
                        <tr className={"donate"}>
                            <td className={"donate"}>{t('donate')}</td>
                            <td className={"donate"}>
                                <div dangerouslySetInnerHTML={{__html: paypal_sundial}}/>
                            </td>
                        </tr>
                    </table>*/}
                </li>
            </ul>
        </div>
    );
}
