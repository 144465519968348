import '../App.css';
import {useTranslation} from "react-i18next";

export default function Contact() {
    const {t, i18n} = useTranslation('',{ keyPrefix: 'contact' });
    return (
        <div className="content">
            <p><a href="mailto:florian@schueller.pro"><img className={"profileimg"} src={"Florian_2021_square_small.jpg"}/><br/>{t('email')}</a></p>
        </div>
    );
}
