import '../App.css';
import {useTranslation} from "react-i18next";

export default function Imprint() {
    const {t, i18n} = useTranslation('',{ keyPrefix: 'imprint' });
    return (
        <div className="content">
            <div className="imprint">Florian Schüller<br/>
                {t('proprietor')}<br/>
                {t('since')}<br/>
            </div>
            <div className="imprint">
                "Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik"<br/>
            </div>
            <div className="imprint">
                {t('location')}: Dingelstedtgasse 33, 3003 Gablitz<br/>
                {t('UID')}: ATU71321401<br/>
                GLN: 9110023875552<br/>
                {t('GISA')}: 28969891<br/>
            </div>
            <div className="imprint">
                <a href={"https://firmen.wko.at/dipl-ing-florian-sch%C3%BCller/nieder%C3%B6sterreich/?firmaid=d7a474f3-04af-4b09-8a56-02567bbedabe"}>{t('WKO')}</a>
            </div>
        </div>
    );
}
