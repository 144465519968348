import ReactMarkdownWith from 'react-markdown';
import '../../App.css';
import {useEffect, useState} from "react";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {useTranslation} from "react-i18next";


function OnlyEnglishWarning(props: { lang: string }) {
    if (props.lang == "de") {
        return (<p className={"note"}>Inhalt nur auf Englisch verfügbar</p>)
    } else {
        return (<></>)
    }
}

export default function Markdown_renderer(data: { project_name: string, project_url: string, base_URL: string, markdown: string }) {
    const [markdown, setMarkdown] = useState("Beschreibung von \"" + data.project_name + "\" wird geladen...");

    const {t, i18n} = useTranslation('');
    const getGitHubUserWithFetch = async () => {

        const response = await fetch(data.base_URL + data.markdown);
        const markdownData = await response.text();
        setMarkdown(markdownData);
    };

    useEffect(() => {
        getGitHubUserWithFetch().then();
    }, []);

    function remote_url(src: string, alt: string, title: string | null) {
        return data.base_URL + src
    }

    return (
        <div className="content">
            <OnlyEnglishWarning lang={i18n.language}/>
            <div className="markdown">
                <ReactMarkdownWith rehypePlugins={[remarkGfm, rehypeRaw]} transformImageUri={remote_url}
                                   children={markdown}
                                   components={{
                                       h1: ({node, ...props}) => <a href={data.project_url}><h1 {...props} /></a>,
                                       p: ({node, ...props}) => <p className={"markdown_paragraph"} {...props} />
                                   }}/>
            </div>
        </div>
    );
}
