import '../App.css';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {WebsiteCarbonBadge} from "react-websitecarbon-badge";


const Intro = (): JSX.Element => {
    const {t, i18n} = useTranslation('', {keyPrefix: 'intro'});
    return (
        <div className="content">
            <h1>{t('title')}</h1>
            {/*<div>Haupttätigkeitsfelder:</div>*/}
            <ul className="knowhow_list">
                {(t('knowhow_list', {returnObjects: true}) as []).map((item: string) => <li key={item}>{item}</li>)}
            </ul>
            <div className={"repair_bonus"}>
                <a href="https://www.reparaturbonus.at/unternehmen/florian-schueller">
                    <img title={t('repair_bonus')} alt={t('repair_bonus')} className={"repair_bonus"}
                         src={"reparaturbonus_banner_small.jpg"}/>
                </a>
            </div>
            <div className={"website_carbon_footer"}>
                <WebsiteCarbonBadge dark={true} url={"https://schueller.pro"}/>
            </div>
        </div>
    );
}

export default Intro;